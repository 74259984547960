$(() => {
	const TSS_REPOSITORY = {
		endpoint: 'https://tts.actum.dev',

		async getVoices() {
			const url = `${this.endpoint}/voices`;
			const response = await fetch(url);
			return response.json();
		},

		async synthesize(payload) {
			const url = `${this.endpoint}/synthesize`;
			const response = fetch(url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(payload),
			});
			return response;
		},
	};

	const TSS = {
		form: document.querySelector('.js-tss-form'),
		voices: null,
		languages: null,

		init(repository) {
			this.repository = repository;

			this.initEvents();
			this.loadVoices();
		},

		initEvents() {
			this.form.addEventListener('submit', (event) => {
				event.preventDefault();
				this.submitForm();
			});

			this.form.addEventListener('change', (event) => {
				if (event.target.id === 'id_language') {
					this.setVoices();
				}
			});

			document.querySelector('.js-tss-play').addEventListener('click', () => {
				const audio = document.querySelector('.js-tss-audio');
				if (audio.paused) {
					this.playAudio();
				} else {
					this.pauseAudio();
				}
			});
		},

		async loadVoices() {
			const response = await this.repository.getVoices();

			this.voices = response.voices;
			this.languages = new Set();
			Object.keys(response.voices).forEach((voice) => {
				this.languages.add(response.voices[voice].language);
			});

			this.setLanguage();
		},

		setLanguage() {
			const languageEl = this.form.querySelector('#id_language');
			languageEl.innerHTML = '';
			this.languages.forEach((language) => {
				const option = document.createElement('option');
				option.value = language;
				option.text = language;
				languageEl.appendChild(option);
			});
			languageEl.dispatchEvent(new Event('change', { bubbles: true }));
		},

		setVoices() {
			const languageEl = this.form.querySelector('#id_language');
			const voiceEl = this.form.querySelector('#id_voice');
			voiceEl.innerHTML = '';

			Object.keys(this.voices).forEach((voice) => {
				if (this.voices[voice].language === languageEl.value) {
					const voiceDetail = this.voices[voice];
					const option = document.createElement('option');
					option.value = voice;
					option.text = voiceDetail.name;
					voiceEl.appendChild(option);
				}
			});
		},

		async submitForm() {
			const formData = new FormData(this.form);
			const payload = {
				text: formData.get('text'),
				samplerate: 22050,
				offline: true,
				voice_name: formData.get('voice_name'),
				speed: Number.parseFloat(formData.get('speed')),
				pitch: Number.parseFloat(formData.get('pitch')),
			};

			const response = await this.repository.synthesize(payload);
			response.arrayBuffer().then((buffer) => {
				const wav = this.addWavHeader(buffer);
				const blob = new Blob([wav], { type: 'audio/wav' });
				const url = URL.createObjectURL(blob);
				const audio = document.querySelector('.js-tss-audio');
				audio.src = url;
				audio.load();
				this.showSynthesize();
				this.playAudio();

				const download = document.querySelector('.js-tss-download');
				download.href = url;
			});
		},

		addWavHeader(wavData) {
			const header = new Uint8Array(44); // WAV header
			const view = new DataView(header.buffer);
			const bufferSize = wavData.bytesLength;
			const numChannels = 1;
			const sampleRate = 22050;
			const bitsPerSample = 16;

			// Set RIFF header
			this.writeString(view, 0, 'RIFF');
			view.setUint32(4, 36 + bufferSize, true);
			this.writeString(view, 8, 'WAVE');

			// Set fmt subchunk
			this.writeString(view, 12, 'fmt ');
			view.setUint32(16, 16, true); // Sub-chunk size
			view.setUint16(20, 1, true); // Audio format (1 for PCM)
			view.setUint16(22, numChannels, true);
			view.setUint32(24, sampleRate, true);
			view.setUint32(28, (sampleRate * numChannels * bitsPerSample) / 8, true); // Byte rate
			view.setUint16(32, (numChannels * bitsPerSample) / 8, true); // Block align
			view.setUint16(34, bitsPerSample, true);

			// Set data subchunk
			this.writeString(view, 36, 'data');
			view.setUint32(40, bufferSize, true);

			// Merge the header and original WAV data into a single ArrayBuffer
			const wavWithHeader = new Uint8Array(header.length + wavData.byteLength);
			wavWithHeader.set(header);
			wavWithHeader.set(new Uint8Array(wavData), header.length);

			return wavWithHeader.buffer; // Return as ArrayBuffer
		},

		writeString(view, offset, string) {
			for (let i = 0; i < string.length; i += 1) {
				view.setUint8(offset + i, string.charCodeAt(i));
			}
		},

		showSynthesize() {
			document.querySelector('.js-tss-synthesized').style.display = 'block';
		},

		playAudio() {
			const audio = document.querySelector('.js-tss-audio');
			document.querySelector('.js-tss-play').innerHTML = '<i class="d-inline-block align-middle icons__icon-stop-14"></i>';
			audio.play();
		},

		pauseAudio() {
			const audio = document.querySelector('.js-tss-audio');
			document.querySelector('.js-tss-play').innerHTML = '<i class="d-inline-block align-middle icons__icon-play"></i>';
			audio.pause();
		},
	};
	window.TSS = TSS;
	TSS.init(TSS_REPOSITORY);
});
