$(() => {
	const ASR_REPOSITORY = {
		endpoint: 'wss://asr.actum.dev/v2/recognize',

		async getLanguages() {
			const url = 'https://asr.actum.dev/languages';
			const response = await fetch(url);
			return response.json();
		},

		wsConnect() {
			const url = this.endpoint;

			this.socket = new WebSocket(url);
			this.socket.onopen = (e) => this.wsOpen(e);
			this.socket.onclose = (e) => this.wsClose(e);
			this.socket.onerror = (e) => this.wsError(e);
			this.socket.onmessage = (e) => this.wsMessage(e);
			return this.socket;
		},

		wsOpen(e) {
			window.console.log('wsOpen', e);
		},

		wsClose(e) {
			window.console.log('wsClose', e);
		},

		wsError(e) {
			window.console.error('wsError', e);
		},

		wsMessage(e) {
			window.console.log('wsMessage', e);
		},

		wsSend(data) {
			this.socket.send(data);
		},
	};

	const ASR = {
		startButton: document.querySelector('.js-asr-start'),
		stopButton: document.querySelector('.js-asr-stop'),
		form: document.querySelector('.js-asr-form'),
		recognition: document.querySelector('.js-asr-recognition'),
		language: document.querySelector('.js-asr-language'),

		init(repository) {
			this.repository = repository;

			this.initEvents();
			this.loadLanguages();
		},

		initEvents() {
			this.startButton.addEventListener('click', () => {
				this.startSession();
				this.recognition.innerHTML = '';
			});

			this.stopButton.addEventListener('click', () => {
				this.stopSession();
			});
		},

		initWsEvents() {
			this.repository.socket.onmessage = (e) => {
				const data = JSON.parse(e.data);
				window.console.log('wsMessage', data);

				if (data.command === 'start_recognition' && data.status === 'ok') {
					window.console.log('Recognize session started', data.session);
					navigator.mediaDevices.getUserMedia({ audio: true })
						.then((stream) => {
							this.stream = stream;
							this.sendSoundBufferToWs(stream);
						})
						.catch((err) => {
							window.console.error('Error accessing microphone:', err);
						});
				}

				if (['text', 'is_final'].every((key) => Object.keys(data).includes(key))) {
					this.processRecognitionChunk(data);
				}
			};
		},

		async loadLanguages() {
			const response = await this.repository.getLanguages();

			this.languages = response.languages;
			this.setLanguage();
		},

		setLanguage() {
			this.language.innerHTML = '';
			Object.keys(this.languages).forEach((key) => {
				const option = document.createElement('option');
				option.value = key;
				option.text = this.languages[key];
				this.language.appendChild(option);
			});
			this.language.dispatchEvent(new Event('change', { bubbles: true }));
		},

		async startSession() {
			this.repository.wsConnect();
			this.sendRecognizeCommand();
			this.form.style.display = 'none';
		},

		stopSession() {
			this.startButton.style.display = 'inline-block';
			this.stopButton.style.display = 'none';
			this.repository.socket.close();
			this.stream.getTracks()[0].stop();
			this.processor.onaudioprocess = null;
			this.form.style.display = 'block';
		},

		async sendRecognizeCommand() {
			setTimeout(() => {
				if (this.repository.socket.readyState === 1) {
					const data = {
						command: 'start_recognition',
						params: {
							language_code: this.language.value,
							automatic_punctuation: true,
							sample_rate: 16000,
						},
					};
					this.repository.wsSend(JSON.stringify(data));
					this.initWsEvents();
				} else {
					window.console.log('Wait for connection WS...');
					this.sendRecognizeCommand();
					this.startButton.style.display = 'none';
					this.stopButton.style.display = 'inline-block';
				}
			}, 5);
		},

		sendSoundBufferToWs(stream) {
			const audioContext = new AudioContext({ sampleRate: 16000 });
			const input = audioContext.createMediaStreamSource(stream);
			const processor = audioContext.createScriptProcessor(4096, 1, 1);

			input.connect(processor);
			processor.connect(audioContext.destination);

			processor.onaudioprocess = (e) => {
				const inputData = e.inputBuffer.getChannelData(0);
				const int16Array = new Int16Array(inputData.length);

				for (let i = 0; i < inputData.length; i += 1) {
					int16Array[i] = inputData[i] * 32767;
				}

				// Convert int16Array to ArrayBuffer
				const { buffer } = int16Array;
				this.repository.socket.send(buffer);
			};

			this.input = input;
			this.processor = processor;
		},

		processRecognitionChunk(data) {
			if (this.recognitionDomElement === undefined) {
				this.recognitionDomElement = this.recognition.insertAdjacentElement('beforeend', document.createElement('div'));
			}

			this.recognitionDomElement.innerHTML = data.text;

			if (data.is_final) {
				this.recognitionDomElement = undefined;
			}
		},
	};
	window.ASR = ASR;
	ASR.init(ASR_REPOSITORY);
});
