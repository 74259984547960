$(() => {
	const getDomPath = (el) => {
		let nodeName = el.nodeName.toLowerCase();
		if (el === document.body) return 'body';
		if (el.id) nodeName += `#${el.id}`;
		else if (el.classList.length) nodeName += `.${[...el.classList].join('.')}`;
		return `${getDomPath(el.parentNode)} ${nodeName}`;
	};

	const SWIPER = {
		init() {
			document.querySelectorAll('.js-swiper').forEach((swiper) => {
				const dataSwiper = JSON.parse(swiper.getAttribute('data-swiper')) || {};
				const titles = [];

				swiper.querySelectorAll('.swiper-slide').forEach((elem) => {
					titles.push(elem.getAttribute('data-title'));
				});

				const defaultParams = {
					direction: 'horizontal',
					fadeEffect: { crossFade: true },
					slidesPerView: 4,
					spaceBetween: 0,
					roundLengths: true,
					loop: false,
					pagination: {
						el: swiper.querySelector('.js-swiper-pagination'),
						clickable: true,
						bulletClass: 'swiper__page',
						bulletActiveClass: 'swiper__page-active',
						renderBullet: (index, className) => `<div class="${className}">${titles[index]}</div>`,
					},
					navigation: {
						prevEl: swiper.querySelector('.js-swiper-left'),
						nextEl: swiper.querySelector('.js-swiper-right'),
					},
				};
				const params = Object.assign(defaultParams, dataSwiper);

				// eslint-disable-next-line
				new Swiper(getDomPath(swiper), params);
			});
		},
	};
	SWIPER.init();

	const RANGE = {
		init() {
			const { noUiSlider } = window;
			if (!noUiSlider) {
				return;
			}

			this.noUiSlider = noUiSlider;
			this.initConnects();
		},

		initConnects() {
			const rangeConnects = document.querySelectorAll('.js-range-connect');

			rangeConnects.forEach((el) => {
				const rangeInput = el.closest('.js-range').querySelector('.js-range-input');

				const minValue = +el.getAttribute('data-min').replace(',', '.');
				const maxValue = +el.getAttribute('data-max').replace(',', '.');
				const step = +el.getAttribute('data-step').replace(',', '.');
				const initial = +el.getAttribute('data-initial').replace(',', '.');
				const suffix = el.getAttribute('data-suffix') || '';

				this.noUiSlider.create(el, {
					start: initial,
					range: {
						min: minValue,
						max: maxValue,
					},
					behaviour: 'tap-drag',
					connect: 'lower',
					step,
				});

				el.noUiSlider.on('update', (value) => {
					rangeInput.value = Number(value).toLocaleString('ru-RU');
					rangeInput.value += suffix;
				});
			});
		},
	};
	RANGE.init();
});
